import { LocalstorageService } from "./localstorage.service";
import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
  CanLoad,
  Route,
} from "@angular/router";
import { Observable } from "rxjs";
import { TOKEN_HEADER, keys, API_ENDPOINT } from "../utils/constants";
import { SessionstorageService } from "./sessionstorage.service";
import { CookieService } from "ngx-cookie-service";

@Injectable({
  providedIn: "root",
})
export class AuthGuardGuard implements CanActivate {
  constructor(
    private sessionStorage: SessionstorageService,
    private localStorageService: LocalstorageService,
    private route: Router,
    private cookieService: CookieService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const userType = this.cookieService.get("userType");
    if (this.sessionStorage.getItem(TOKEN_HEADER)) return true;
    if (userType === "medico") {
      this.route.navigate([API_ENDPOINT.LOGIN_MEDICO]);
    } else {
      this.route.navigate([API_ENDPOINT.LOGIN_PACIENTE]);
    }
    // this.route.navigate(["/login"]);
    return false;
  }
}

import { Injectable } from "@angular/core";
import CryptoJS from "crypto-js";

var SECRET_KEY = '9Q9^0mNRV-Qzh}(';

@Injectable({
  providedIn: "root",
})
export class SessionstorageService {
  constructor() {}

  public clear(){
    sessionStorage.clear();
  }
  public setItem(key: string, value: string) {
    sessionStorage.setItem(this.hash(key), this.encrypt(value));
  }

  public removeItem(key: string): void {
    sessionStorage.removeItem(this.hash(key));
  }

  public getItem(key: string): string {
    if (sessionStorage.getItem(this.hash(key))) 
      return this.decrypt(sessionStorage.getItem(this.hash(key)));
    return undefined;
  }

  private hash(key){
    return CryptoJS.SHA256(key, SECRET_KEY).toString();
  }
  private encrypt(data) {

    return CryptoJS.AES.encrypt(data, SECRET_KEY).toString();
  }
  private decrypt(data) {
    return CryptoJS.AES.decrypt(data, SECRET_KEY).toString(CryptoJS.enc.Utf8);
  }
  
}

import { AuthenticationService } from "./authentication.service";
import { ToastrService } from "ngx-toastr";
import { SessionstorageService } from "./sessionstorage.service";
import { Injectable } from "@angular/core";
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { TOKEN_HEADER } from "../utils/constants";
import { catchError } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class InterceptorService implements HttpInterceptor {
  constructor(
    private sessionStorageService: SessionstorageService,
    private toastrService: ToastrService,
    private auth: AuthenticationService
  ) {}

  public intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    ////console.log("interceptor");
    const token = this.sessionStorageService.getItem(TOKEN_HEADER);
    let headers = request.headers;
    if (token)
      headers = request.headers.append(TOKEN_HEADER, `Bearer ${token}`);
    request = request.clone({ headers });
    ////console.log(request);
    // return next.handle(request);
    return next
      .handle(request)
      .pipe(catchError((error: HttpErrorResponse) => this.handlerError(error)));
  }
  private handlerError(error: HttpErrorResponse) {
    if (error.status === 401) {
      // this.toastrService.error(error.error.message, "Error Inicio sesión");
      this.auth.logout().then(() => {});
    }
    return throwError(error.error);
  }
}

import { keys } from "src/app/utils/constants";
import { LocalstorageService } from "./localstorage.service";
import { API_ENDPOINT, TOKEN_HEADER } from "./../utils/constants";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { SessionstorageService } from "./sessionstorage.service";
import { CookieService } from "ngx-cookie-service";

@Injectable({
  providedIn: "root",
})
export class AuthenticationService {
  constructor(
    private httpClient: HttpClient,
    private sessionStorage: SessionstorageService,
    private cookieService: CookieService,
    protected http: HttpClient
  ) {}
  public login(body: any): Observable<any> {
    const userType = this.cookieService.get("userType");
    return this.httpClient.post(
      environment.apiEndpoint + API_ENDPOINT.LOGIN,
      body
    );
  }

  findUser(userName: String, tipo: String) {
    return this.http.post(environment.apiEndpoint + API_ENDPOINT.RESET, {
      userName,
      tipo,
    });
  }
  savePass(password: String, token: String, userId : number) {
    return this.http.post(environment.apiEndpoint + API_ENDPOINT.CHANGE_PASS, {
      password,
      token,
      userId
    });
  }
  checkToken(token: String) {
    return this.http.get(`${environment.apiEndpoint}/password/reset/${token}`);
  }

  public async logout(): Promise<void> {
    return await this.sessionStorage.clear();
  }
  public getUser() {
    return this.sessionStorage.getItem("_username");
  }
  loginInforme(userName: String, tipo: String, pedidoId: number) {
    return this.http.post(environment.apiEndpoint + API_ENDPOINT.LOGININFORME, {
      userName,
      tipo,
      pedidoId
    });
  }
}

import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AuthGuardGuard } from "./services/auth-guard.guard";

const routes: Routes = [
  // {
  //   path: "paciente",
  //   loadChildren: "./application/application.module#ApplicationModule",
  //   canActivate: [AuthGuardGuard],
  //   // canLoad: [AuthGuardGuard],
  // },
  {
    path: "wpm",
    loadChildren: "./application/application.module#ApplicationModule",
    canActivate: [AuthGuardGuard],
    // canLoad: [AuthGuardGuard],
  },
  // {
  //   path: "",
  //   loadChildren: "./sesion/sesion.module#SesionModule",
  // },
  {
    path: "login/paciente",
    loadChildren: "./login/login.module#LoginModule",
  },
  {
    path: "login/paciente/informe/:orderId",
    loadChildren: "./loginInforme/loginInforme.module#LoginInformeModule",
  },
  {
    path: "login/medico",
    loadChildren: "./login/login.module#LoginModule",
  },
  // {
  //   path: "sin-respuesta",
  //   loadChildren: "./notfound/notfound.module#NotfoundModule",
  // },
  {
    path: "password-reset",
    loadChildren:
      "./sendMailPassword/sendMailPassword.module#SendMailPasswordModule",
  },
  {
    path: "reset/:id",
    loadChildren: "./resetPassword/resetPassword.module#ResetPasswordModule",
  },
  { path: "**", redirectTo: "sin-respuesta" },
  // {
  //   path: "**",
  //   redirectTo: "",
  // },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}

import { MaterialModule } from "./material.module";
import { BrowserModule, Title } from "@angular/platform-browser";
import { NgModule, LOCALE_ID } from "@angular/core";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import localEs from "@angular/common/locales/es";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { registerLocaleData } from "@angular/common";
import { InterceptorService } from "./services/interceptor.service";
import { SearchService } from "./services/search.service";
import { ToastrModule } from "ngx-toastr";
import { MatPaginatorIntl } from "@angular/material";
import { paginatorTranslate } from "./paginatorTranslate";
import { BnNgIdleService } from "bn-ng-idle";
import { CookieService } from "ngx-cookie-service";
import { LocationStrategy, HashLocationStrategy } from "@angular/common";

//I keep the new line
registerLocaleData(localEs, "es");
@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    ToastrModule.forRoot({
      timeOut: 7000,
      preventDuplicates: true,
      progressBar: true,
      closeButton: true,
      tapToDismiss: false,
    }),
  ],
  providers: [
    Title,
    { provide: LOCALE_ID, useValue: "es" },
    { provide: MatPaginatorIntl, useValue: paginatorTranslate() },
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorService,
      multi: true,
    },
    BnNgIdleService,
    CookieService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

import { MatPaginatorIntl } from "@angular/material";

const getRangeLabel = (page: number, pageSize: number, length: number) => {
  if (length === 0 || pageSize === 0) {
    return `0 de ${length}`;
  }

  length = Math.max(length, 0);

  const startIndex = page * pageSize;

  const endIndex =
    startIndex < length
      ? Math.min(startIndex + pageSize, length)
      : startIndex + pageSize;

  return `Página ${page + 1}`;
};

export function paginatorTranslate() {
  const paginatorIntl = new MatPaginatorIntl();

  paginatorIntl.itemsPerPageLabel = "Cantidad por página:";
  paginatorIntl.nextPageLabel = "Siguiente";
  paginatorIntl.previousPageLabel = "Anterior ";
  paginatorIntl.firstPageLabel = "Primera pagina";
  paginatorIntl.lastPageLabel = "Última pagina";
  paginatorIntl.getRangeLabel = getRangeLabel;

  return paginatorIntl;
}

import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { LocalstorageService } from "src/app/services/localstorage.service";
import { keys } from "src/app/utils/constants";
import { BnNgIdleService } from "bn-ng-idle";
import { CookieService } from "ngx-cookie-service";
import { API_ENDPOINT } from "src/app/utils/constants";
import { AuthenticationService } from "src/app/services/authentication.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  public userType: string = "";

  public constructor(
    private bnIdle: BnNgIdleService,

    private localStorage: LocalstorageService,
    private cookieService: CookieService,
    private auth: AuthenticationService,
    private route: Router
  ) {}

  ngOnInit() {
    var timeOut = localStorage.getItem("INACTIVITY_TIMEOUT");
    this.userType = this.localStorage
      .getItem(keys.USER_TYPE)
      .substr(0, 1)
      .toUpperCase();

    let userType_ = this.cookieService.get("userType");
    this.localStorage.setItem("userType", userType_);
    this.cookieService.set("userType", userType_);
    this.bnIdle
      .startWatching(timeOut || 600)
      .subscribe((isTimedOut: boolean) => {
        if (isTimedOut) {
          this.auth.logout().then(() => {
            // this.router.navigate(["/login/medico"]);
            if (this.cookieService.get("userType") === "medico") {
              this.route.navigate([API_ENDPOINT.LOGIN_MEDICO]);
            } else {
              this.route.navigate([API_ENDPOINT.LOGIN_PACIENTE]);
            }
          });
        }
      });
  }
}
